import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Article from "../components/article"
import HighlightsList from "../components/highlightsList"
import HTMLHeader from "../components/htmlHeader"
import { convertPostToOldFormat } from "../lib/wpGraphQLQueryAdapter"

const Content = styled.div`
  width: calc(100% - 230px);
  display: flex;
  flex-direction: column;
  margin: 60px 0 0 0;
  border-right: 1px solid #c4c4c4;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    margin: 30px 0 0 0;
    border-right: none;
  }
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c4c4c4;
`

const Title = styled.h1`
  font-size: 48px;
  color: black;
  font-family: "MinionPro";
  font-weight: 400;
  margin: 0 0 20px 0;

  @media (max-width: 700px) {
    font-size: 36px;
  }
`

const Description = styled.p`
  width: 600px;
  font-size: 16px;
  font-family: "franklinGothic";
  margin: 0 0 30px 0;
  color: black;

  @media (max-width: 1000px) {
    width: calc(100% - 30px);
  }

  @media (max-width: 700px) {
    width: calc(100% - 15px);
    margin: 0 0 20px 0;
  }
`

const SeeMoreButton = styled.button`
  cursor: pointer;
  width: 150px;
  height: 45px;
  display: ${props =>
    props.moreArticlesActive || props.showButton < 4 ? "none" : "flex"};
  justify-content: center;
  align-items: center;
  border: 1px solid #294973;
  font-size: 14px;
  color: black;
  font-family: "franklinGothic";
  margin: 30px 0 0 0;
  background-color: white;
`

const Tag = ({ data }) => {
  const [matchingArticles, setMatchingArticles] = useState([])
  const [moreArticlesActive, setMoreArticlesActive] = useState(false)

  const showMoreArticles = () => {
    setMoreArticlesActive(true)
  }

  const posts = data.allWpPost.edges.map(convertPostToOldFormat);

  useEffect(() => {
    setMatchingArticles(matchingArticles => [...posts])
  }, [])

  const tagArticles = matchingArticles.slice(0, 3).map((article, i) => {
    const post = article.node
    return (
      <Article
        title={post.title}
        slug={post.slug}
        author={post.author.name}
        date={post.date}
        featured_image={post.featured_media}
        excerpt={post.excerpt}
        moreArticlesActive={true}
        key={`key-${i}-${post.slug}`}
      />
    )
  })

  const extraTagArticles = matchingArticles.slice(3).map((article, i) => {
    const post = article.node
    return (
      <Article
        title={post.title}
        slug={post.slug}
        author={post.author.name}
        date={post.date}
        featured_image={post.featured_media}
        excerpt={post.excerpt}
        moreArticlesActive={moreArticlesActive}
        key={`key-${i}-${post.slug}`}
      />
    )
  })
  return (
    <>
      <HTMLHeader />
      <Content>
        <TitleContainer>
          <Title>{data.wpTag.name}</Title>
          <Description>
            A description of this section. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat.
          </Description>
        </TitleContainer>
        {tagArticles}
        {extraTagArticles}
        <SeeMoreButton
          moreArticlesActive={moreArticlesActive}
          onClick={showMoreArticles}
          showButton={posts.length}
        >
          See more
        </SeeMoreButton>
      </Content>
    </>
  )
}

export default Tag

export const tagQuery = graphql`
  query($tag: String!) {
    allWpPost(filter: { tags: { nodes: { elemMatch: { name: { eq: $tag } } } } }, sort: {fields: date, order: DESC}) {
      edges {
        node {
          title
          slug
          excerpt
          featuredImage {
            node {
              title
              sourceUrl
            }
          }
          author {
            node {
              name
            }
          }
          date
          tags {
            nodes {
              slug
            }
          }
        }
      }
    }
    wpTag(name: { eq: $tag }) {
      name
    }
  }
`
